// src/components/ContactUs.js
import React from 'react';
import './ContactUs.css'; // Import the CSS file for styling
import Navbar from './Navbar';
import AdSenseComponent from './AdSenseComponent';// Import AdSenseComponent*

const ContactUs = () => {
  return (
   
    <div className="contact-us">
       <Navbar/>
       <br>
       </br>
      <h1>Contact Us</h1>
      <p>Address:</p>
      <p>Incubation center SKIT, Jagatpura, Jaipur</p>
      <p>Email:</p>
      <p>contact@hirespoof.com</p>
      <AdSenseComponent />
    </div>
  );
}

export default ContactUs;
