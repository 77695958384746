import React from 'react';
import Navbar from './Navbar'; // Import the Navbar component
import Sidebar from './Sidebar'; // Import the Sidebar component
import './OtherJobs.css';

const otherJobs = [
  {
    title: 'Product Manager',
    company: 'Tech Innovators | Mumbai, India',
    skills: ['Agile', 'Scrum', 'Product Management'],
    salary: '₹12 LPA - 15 LPA',
    startDate: 'Immediate',
    openings: 2,
    experience: '2-5 years',
  },
  {
    title: 'Marketing Specialist',
    company: 'Creative Minds | Pune, India',
    skills: ['SEO', 'Content Marketing', 'Social Media'],
    salary: '₹8 LPA - 10 LPA',
    startDate: 'Immediate',
    openings: 1,
    experience: '1-3 years',
  },
];

const OtherJobsPage = () => {
  return (
    <div className="other-jobs-page">
      <Navbar />
      <div className="content-container">
        <Sidebar />
        <div className="main-content">
          <div className="search-container">
            <input type="text" placeholder="Search by Job Title / Skills" className="search-bar" />
            <button className="filter-button">Sort by (Date)</button>
          </div>
          <div className="job-listings">
            {otherJobs.map((job, index) => (
              <div className="job-card" key={index}>
                <h3>{job.title}</h3>
                <p>{job.company}</p>
                <div className="skills">
                  {job.skills.map((skill, i) => (
                    <span key={i}>{skill}</span>
                  ))}
                </div>
                <div className="job-details">
                  <span>Salary: {job.salary}</span>
                  <span>Start Date: {job.startDate}</span>
                  <span>Openings: {job.openings}</span>
                  <span>Experience: {job.experience}</span>
                </div>
                <div className="job-actions">
                  <button>View Details</button>
                  <button>Apply Now</button>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="filters">
          <h3>Apply Filters</h3>
          <div>
            <label>Job Type</label>
            <select>
              <option>Select Type</option>
            </select>
          </div>
          <div>
            <label>Experience Level</label>
            <select>
              <option>Select Level</option>
            </select>
          </div>
          <div>
            <label>Remote Jobs</label>
            <input type="checkbox" />
          </div>
          <div>
            <label>Min Salary</label>
            <input type="range" min="3" max="20" step="1" />
          </div>
          <button>Apply</button>
        </div>
      </div>
    </div>
  );
};

export default OtherJobsPage;
