import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider, signInWithPopup, createUserWithEmailAndPassword } from "firebase/auth";
import { getFirestore, doc, updateDoc, setDoc } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyA82lAfE_9vxLuhX_8EmW_3GjA863XbGSA",
  authDomain: "hirespoof.firebaseapp.com",
  projectId: "hirespoof",
  storageBucket: "hirespoof.appspot.com",
  messagingSenderId: "446729781952",
  appId: "1:446729781952:web:9087b10ec96dc72d856325",
  measurementId: "G-Y7DY4FGHD0"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const provider = new GoogleAuthProvider();

const signInWithGoogle = () => signInWithPopup(auth, provider);

const registerUser = async (email, password, referralCode) => {
  try {
    const credential = await createUserWithEmailAndPassword(auth, email, password);
    const user = credential.user;
    const userRef = doc(db, 'users', user.uid);
    const referrerDoc = await doc(db, 'users', referralCode).get();

    // Update referrer's coins
    if (referrerDoc.exists()) {
      const referrerData = referrerDoc.data();
      await updateDoc(referrerDoc.ref, {
        coins: referrerData.coins + 20 // Increase coins by 20 for each successful referral signup
      });
    } else {
      console.error('Referrer not found.');
    }

    // Additional user data initialization (if needed)
    await setDoc(userRef, {
      email: user.email,
      // Add other user data as needed
    });

    return user;
  } catch (error) {
    console.error('Error registering user:', error);
    throw error;
  }
};

export { auth, signInWithGoogle, db, provider, registerUser };
