import React, { useEffect, useState, useRef } from 'react';
import { auth, db } from './firebaseConfig';
import { onAuthStateChanged } from 'firebase/auth';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import './ReferAndEarn.css';
import earn from './assets/icons/earn.png';
import net from './assets/icons/net.png';
import Navbar from './Navbar';
import Sidebar from './Sidebar';
import { useCoins } from './CoinContext';
import Footer from './Footer';

const ReferAndEarn = () => {
  const [activeTab, setActiveTab] = useState('terms');
  const [user, setUser] = useState(null);
  const [referralLink, setReferralLink] = useState('Loading your referral link...');
  const [coins, setCoins] = useState(0);
  const [error, setError] = useState(null);
  const termsRef = useRef(null);
  const rewardsRef = useRef(null);
  const { setCoins: setContextCoins } = useCoins(); // Access setCoins function from context

  useEffect(() => {
    const fetchUserData = async (userId) => {
      try {
        const userDoc = await getDoc(doc(db, 'users', userId));
        if (userDoc.exists()) {
          const userData = userDoc.data();
          setReferralLink(`https://www.hirespoof.com/?referralCode=${userId}`);
          setCoins(userData.coins);
        } else {
          setError('User data not found.');
        }
      } catch (err) {
        console.error('Failed to fetch user data:', err);
        setError('Failed to fetch user data. Please check your internet connection.');
      }
    };

    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUser(user);
        fetchUserData(user.uid);
      } else {
        setUser(null);
        setReferralLink('Loading your referral link...');
        setCoins(0);
      }
    });

    return () => unsubscribe();
  }, []);

  // Function to handle referral registration and update referrer's coins
  const handleReferralRegistration = async (referrerId) => {
    try {
      const userRef = doc(db, 'users', referrerId);
      await updateDoc(userRef, {
        coins: coins + 20 // Increase coins by 20 for each successful referral signup
      });
      setContextCoins(coins + 20); // Update coins in context
      console.log('Referrer coins updated successfully');
    } catch (err) {
      console.error('Failed to update referrer coins:', err);
    }
  };

  const handleScrollToTerms = () => {
    termsRef.current.scrollIntoView({ behavior: 'smooth' });
    setActiveTab('terms');
  };

  const handleScrollToRewards = () => {
    rewardsRef.current.scrollIntoView({ behavior: 'smooth' });
    setActiveTab('rewards');
  };

  const handleCopyLink = () => {
    navigator.clipboard.writeText(referralLink)
      .then(() => {
        alert('Referral link copied to clipboard!');
      })
      .catch((err) => {
        console.error('Failed to copy text:', err);
        alert('Failed to copy text. Please try again.');
      });
  };

  return (
    <div className="refer-and-earn-page">
      <Navbar />
      <Sidebar />
      <div className="refer-and-earn-content">
        <div className="refer-and-earn-scroll-buttons">
          <button
            className={`refer-and-earn-scroll-button ${activeTab === 'terms' ? 'active' : ''}`}
            onClick={handleScrollToTerms}
          >
            Terms
          </button>
          <button
            className={`refer-and-earn-scroll-button ${activeTab === 'rewards' ? 'active' : ''}`}
            onClick={handleScrollToRewards}
          >
            Rewards
          </button>
        </div>
        <div className="refer-and-earn-header">
          <h1>Invite your friends <span>Earn</span> exciting <span>PRIZES</span></h1>
        </div>
        <div className="refer-and-earn-image">
          <img src={earn} alt="earn" className="refer-and-earn-icon" />
        </div>

        <div className="refer-and-earn-terms" ref={termsRef}>
          <div className="refer-and-earn-terms-content">
            <h2>TERMS TO REFER</h2>
            <p>For every refer and successful signup (Mobile verification)</p>
            <p>Your leaderboard rank will be calculated be on top 50 referee
            get amazing prizes</p>
          </div>
          <div className="refer-and-earn-redeem">
            <h2>REDEEM NOW</h2>
            <button className="refer-and-earn-redeem-button" onClick={handleScrollToRewards}>REWARDS</button>
          </div>
        </div>

        {error && <div className="refer-and-earn-error-message">{error}</div>}

        <div className="refer-and-earn-link">
          <h2>REFERRAL LINK</h2>
          <p>{referralLink}</p>
          <button className="refer-and-earn-copy-button" onClick={handleCopyLink}>Copy Link</button>
        </div>

        <div className="refer-and-earn-how-to-refer">
          <h2>HOW TO REFER</h2>
          <ol>
            <li>Go to our Full-time Job/Internship section.</li>
            <li>Click on Share icon or the gradient strap as shown in the figure. Copy the link and share it with your friends or in groups.</li>
          </ol>
        </div>

        <div className="refer-and-earn-image">
          <img src={net} alt="net" className="refer-and-earn-icon" />
        </div>

        <div className="refer-and-earn-leaderboard">
          <h2><center>LEADERBOARD</center></h2>
          <table className="refer-and-earn-leaderboard-table">
            <thead>
              <tr>
                <th>Rank</th>
                <th>Name</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>1</td>
                <td>Abhay </td>
              </tr>
              <tr>
                <td>2</td>
                <td>Krish</td>
              </tr>
              <tr>
                <td>3</td>
                <td>Tharun Santy</td>
              </tr>
              <tr>
                <td>4</td>
                <td>Prejyot Gupta</td>
              </tr>
              <tr>
                <td>5</td>
                <td>Suresh Patil</td>
              </tr>
              <tr>
                <td>6</td>
                <td>Shiva Kumar B</td>
              </tr>
              <tr>
                <td>7</td>
                <td>Arjun</td>
              </tr>
              <tr>
                <td>8</td>
                <td>Mahant</td>
              </tr>
              <tr>
                <td>9</td>
                <td>Deepak</td>
              </tr>
              <tr>
                <td>10</td>
                <td>Krishan Kumar</td>
              </tr>
            </tbody>
          </table>
        </div>

        <div className="refer-and-earn-rewards" ref={rewardsRef}>
          <h2><center>Rewards</center></h2>
          <div className="refer-and-earn-reward-cards">
            <div className="refer-and-earn-reward-card">
              <img src="https://swedishmatch.cdn.storm.io/1adf5144-3a48-45a9-961a-91305b36bf93.png?w=500" alt="1000rs Amazon Gift card" />
              <p>Refer your friends and Get 1000rs Amazon Gift card (RANK 1) </p>
              {/*<button>Redeem</button>*/}
              </div>
            <div className="refer-and-earn-reward-card">
              <img src="https://swedishmatch.cdn.storm.io/1adf5144-3a48-45a9-961a-91305b36bf93.png?w=500" alt="500rs Amazon Gift card" />
              <p>Refer your friends and Get 500rs Amazon Gift card (RANK 2-10)</p>
              {/*<button>Redeem</button>*/}
            </div>
            <div className="refer-and-earn-reward-card">
              <img src="https://swedishmatch.cdn.storm.io/1adf5144-3a48-45a9-961a-91305b36bf93.png?w=500" alt="300rs Amazon Gift card" />
              <p>Refer your friends and Get 300rs Amazon Gift card (RANK 10-25)</p>
              {/*<button>Redeem</button>*/}
              </div>
            <div className="refer-and-earn-reward-card">
              <img src="https://swedishmatch.cdn.storm.io/1adf5144-3a48-45a9-961a-91305b36bf93.png?w=500" alt="50rs Amazon Gift card" />
              <p>Refer your friends and Get 100rs Amazon Gift card (RANK 25-50)</p>
              {/*<button>Redeem</button>*/}
              </div>
          </div>
        </div>
        <Footer/>

      </div>
    </div>
    
  );
};

export default ReferAndEarn;
