// src/components/ColdMailTemplatesPage.js
import React, { useState } from 'react';
import './ColdMailTemplatesPage.css';
import Navbar from './Navbar';

// Cold mailing templates
const coldMailTemplates = [
  {
    title: "Cold Mail Template 1",
    content: `
Subject: Referral for [Role] – Job ID: [ID]

Dear [Hiring Manager’s Name],

I’m [Your Name], a [Year] B.Tech student at [Your University], with experience at [Company/Internship Name]. I’m very interested in applying for the [Role] at [Company Name] (Job ID: [ID]).
My background in [mention skill or area] and experience with [specific project/task] align well with the role’s requirements. I would love the opportunity to contribute to your team and further develop my skills.
Please find my resume attached. I look forward to hearing from you.
Best regards,

[Your Full Name]
[Your Contact Information]
[LinkedIn Profile]
`,
  },
  {
    title: "Cold Mail Template 2",
    content: `
Subject: Request for Referral – [Role], Job ID: [ID]

Dear [Recipient’s Name],

I hope you are well. I came across the [Role] position at [Company Name] (Job ID: [ID]) and am very excited about the opportunity. Given your experience at [Company Name], I was wondering if you might be willing to provide a referral for this role.
With my background in [mention key skills or area of expertise] and relevant experience at [Company/Internship], I believe I could add value to the team. I'd be grateful for any advice or assistance you could provide regarding my application.
Thank you for your time and support!

Best regards,
[Your Full Name]
`,
  },
];

// LinkedIn message templates
const linkedInTemplates = [
  {
    title: "LinkedIn Message 1",
    content: `
Hi [Recruiter’s Name],
I hope you’re doing well. I’m [Your Name], a Computer Science student from the 2025 batch at [Your University], passionate about becoming a developer. 
I came across the [Role] position at [Company Name] (Job ID: [ID]) and would love to apply.

Would you be open to referring me for this role or sharing insights on the hiring process? I have experience in [mention relevant skills], and I believe my background aligns well with the role.

Thank you for your time, and I look forward to hearing from you!
Best,
[Your Name]
`,
  },
  {
    title: "LinkedIn Message 2",
    content: `
Hi [Recruiter’s Name],
I hope you’re having a great day! My name is [Your Name], and I’m currently a Computer Science student in the 2025 batch at [Your University]. 
I recently found the [Role] position at [Company Name] (Job ID: [ID]) and am very interested in applying.

If you have a moment, I’d greatly appreciate it if you could provide a referral or any tips on how to strengthen my application. 
I have experience in [mention relevant skills or projects] that I believe would be a good fit for the team.

Thank you for considering my request!
Best regards,
[Your Name]
`,
  },
];

const ColdMailTemplatesPage = () => {
  const [selectedTemplateType, setSelectedTemplateType] = useState('coldMail');
  const [isUnlocked, setIsUnlocked] = useState(false);

  // Choose templates based on the selected type
  const templates = selectedTemplateType === 'coldMail' ? coldMailTemplates : linkedInTemplates;

  // Handle unlocking templates
  const handleUnlock = () => {
    window.location.href = "https://topmate.io/hirespoof/1275997";
  };

  return (
    <div className="templates-page">
        <Navbar/>
        <br/>
        <br/>
      <h1 className="page-title">Templates</h1>
      <div className="tabs">
        <button 
          className={selectedTemplateType === 'coldMail' ? 'active' : ''} 
          onClick={() => setSelectedTemplateType('coldMail')}
        >
          Cold Mailing Templates
        </button>
        <button 
          className={selectedTemplateType === 'linkedIn' ? 'active' : ''} 
          onClick={() => setSelectedTemplateType('linkedIn')}
        >
          LinkedIn Message Templates
        </button>
      </div>

      <div className="templates-list">
        {templates.map((template, index) => (
          <div 
            key={index} 
            className={`template ${!isUnlocked && index >= 2 ? 'locked' : ''}`}
          >
            <h3 className="template-title">{template.title}</h3>
            <pre className="template-content">{template.content}</pre>
          </div>
        ))}

        {!isUnlocked && (
          <div className="unlock-section">
            <p>To unlock more templates, click the button below!</p>
            <button className="unlock-button" onClick={handleUnlock}>Pay to Unlock All Templates</button>
          </div>
        )}
      </div>
    </div>
  );
};

export default ColdMailTemplatesPage;
