import React, { useState, useEffect, useRef } from 'react';
import './ATSResumeScore.css'; 
import Navbar from './Navbar';
import { analyzeResume } from './apiService'; 
import { Chart, registerables } from 'chart.js';

Chart.register(...registerables); // Register all necessary Chart.js components

const ATSResumeScore = () => {
    const [score, setScore] = useState(null); 
    const [resumeFile, setResumeFile] = useState(null);
    const [resumeContent, setResumeContent] = useState('');
    const [resumeData, setResumeData] = useState({
        experience: 'Fresher (No full-time experience)',
        bullets: false,
        dates: false,
        measurableImpact: false,
        keywords: false,
        fileDetails: false,
    });
    const [loading, setLoading] = useState(false); // Loading state
    const donutChartRef = useRef(null);  // Donut chart reference
    const barChartRef = useRef(null);    // Bar chart reference

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = (event) => {
                setResumeContent(event.target.result);
                setResumeFile(file);
            };
            reader.readAsText(file);
        }
    };

    const calculateScore = async () => {
        if (!resumeFile) {
            alert('Please upload a resume first!');
            return;
        }

        setLoading(true); // Set loading to true before API call

        try {
            const analysisResult = await analyzeResume(resumeContent); 
            updateResumeData(analysisResult); 
            const totalScore = calculateTotalScore(analysisResult);
            setScore(totalScore);
        } catch (error) {
            console.error('Error analyzing resume:', error);
            setScore(0); // Set score to 0 in case of error
        } finally {
            setLoading(false); // Set loading to false after API call
        }
    };

    const updateResumeData = (analysisResult) => {
        setResumeData({
            experience: analysisResult.experience || 'N/A',
            bullets: analysisResult.bullets,
            dates: analysisResult.dates,
            measurableImpact: analysisResult.measurableImpact,
            keywords: analysisResult.keywords,
            fileDetails: analysisResult.fileDetails,
        });
    };

    const calculateTotalScore = (analysisResult) => {
        let totalScore = 0;

        // Score based on each component
        totalScore += analysisResult.bullets ? 20 : 0; // Check for bullet points
        totalScore += analysisResult.dates ? 20 : 0; // Check for dates
        totalScore += analysisResult.measurableImpact ? 15 : 0; // Check for measurable impact
        totalScore += analysisResult.keywords ? 30 : 0; // Check for relevant keywords
        totalScore += analysisResult.fileDetails ? 15 : 0; // Check for file details

        return totalScore; // Return the total score
    };

    useEffect(() => {
        if (score !== null) {
            renderDonutChart();
            renderBarChart();
        }
    }, [score]); // Re-render charts when the score is updated

    // Render donut chart for resume score
    const renderDonutChart = () => {
        if (donutChartRef.current) {
            // Destroy previous chart instance if exists to prevent duplication
            if (donutChartRef.current.chart) {
                donutChartRef.current.chart.destroy();
            }

            donutChartRef.current.chart = new Chart(donutChartRef.current, {
                type: 'doughnut',
                data: {
                    datasets: [{
                        data: [score, 100 - score],
                        backgroundColor: ['#FFB74D', '#F5F5F5'],
                    }],
                },
                options: {
                    cutout: '70%',
                    plugins: {
                        legend: { display: false },
                        tooltip: { enabled: false },
                    },
                    elements: {
                        center: {
                            text: `${score}%`,
                            color: '#FFB74D',
                            fontStyle: 'Helvetica',
                            sidePadding: 20,
                        },
                    },
                },
            });
        }
    };

    // Render bar chart for resume analysis
    const renderBarChart = () => {
        if (barChartRef.current) {
            // Destroy previous chart instance if exists to prevent duplication
            if (barChartRef.current.chart) {
                barChartRef.current.chart.destroy();
            }

            barChartRef.current.chart = new Chart(barChartRef.current, {
                type: 'bar',
                data: {
                    labels: ['Bullets', 'Dates', 'Impact', 'Keywords', 'File'],
                    datasets: [{
                        label: 'Analysis',
                        data: [
                            resumeData.bullets ? 1 : 0,
                            resumeData.dates ? 1 : 0,
                            resumeData.measurableImpact ? 1 : 0,
                            resumeData.keywords ? 1 : 0,
                            resumeData.fileDetails ? 1 : 0
                        ],
                        backgroundColor: ['#7986CB', '#4FC3F7', '#81C784', '#FFD54F', '#FF8A65'],
                    }],
                },
                options: {
                    scales: {
                        x: {
                            beginAtZero: true,
                            grid: { display: false },
                        },
                        y: {
                            beginAtZero: true,
                            max: 1,
                            ticks: {
                                stepSize: 1,
                                callback: function(value) { return value === 1 ? 'Yes' : 'No'; }
                            }
                        },
                    },
                    plugins: {
                        legend: { display: false },
                    },
                },
            });
        }
    };

    return (
        <div className="ats-score-container">
            <Navbar />
            <div className="resume-analysis">
                <div className="left-panel">
                    <h3>Resume Scan</h3>

                    {/* Display uploaded resume */}
                    {resumeFile && (
                        <div className="resume-preview">
                            <iframe src={URL.createObjectURL(resumeFile)} width="400" height="500" title="Resume Preview"></iframe>
                        </div>
                    )}
                </div>

                <div className="right-panel">
                    <div className="upload-section">
                        <h4>Upload Your Resume</h4>
                        <input 
                            type="file" 
                            accept=".pdf,.doc,.docx" 
                            onChange={handleFileChange}
                        />
                        <button 
                            className="submit-button" 
                            onClick={calculateScore}
                        >
                            Submit & Scan
                        </button>
                    </div>

                    {loading ? ( // Show loader while loading
                        <div className="loader">
                            <p>Calculating your ATS score...</p>
                            <div className="spinner"></div>
                        </div>
                    ) : (
                        score !== null && (
                            <>
                                <div className="score-section">
                                    {/* Donut Chart for Resume Score */}
                                    <div className="score-card">
                                        <canvas ref={donutChartRef}></canvas>
                                        <h2>{score}%</h2>
                                        <p>Your score is {score} out of 100.</p>
                                    </div>

                                    {/* Experience Details */}
                                    <div className="resume-details">
                                        <h4>Experience: {resumeData.experience}</h4>
                                    </div>
                                </div>

                                {/* Bar Chart for Competencies */}
                                <div className="improvement-section">
                                    <h4>Competencies</h4>
                                    <canvas ref={barChartRef}></canvas>
                                </div>
                            </>
                        )
                    )}
                </div>
            </div>
        </div>
    );
};  

export default ATSResumeScore;
