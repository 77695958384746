import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { signInWithGoogle, auth } from './components/firebaseConfig';
import { signInWithEmailAndPassword, sendPasswordResetEmail } from 'firebase/auth';
import './AuthStyles.css';  // Import the CSS file

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [resetEmail, setResetEmail] = useState(''); // New state for reset email
  const [showReset, setShowReset] = useState(false); // New state for showing reset form
  const navigate = useNavigate();

  const handleGoogleSignIn = async () => {
    try {
      await signInWithGoogle();
      alert('Signed in with Google');
      navigate('/internships');
    } catch (error) {
      console.error(error);
      alert('Failed to sign in with Google');
    }
  };

  const handleEmailSignIn = async (e) => {
    e.preventDefault();
    try {
      await signInWithEmailAndPassword(auth, email, password);
      alert('Signed in successfully');
      navigate('/internships');
    } catch (error) {
      console.error(error);
      alert('Failed to sign in');
    }
  };

  const handlePasswordReset = async (e) => {
    e.preventDefault();
    try {
      await sendPasswordResetEmail(auth, resetEmail);
      alert('Password reset email sent. Check your inbox.');
      setShowReset(false); // Hide the reset form after sending email
    } catch (error) {
      console.error(error);
      alert('Failed to send password reset email');
    }
  };

  return (
    <div className="auth-container">
      <div className="auth-image"></div>
      <div className="auth-form-container">
        <div className="auth-form">
          <h2>Welcome back to Hirespoof</h2>
          {showReset ? (
            <form onSubmit={handlePasswordReset}>
              <input
                type="email"
                placeholder="Enter your email"
                value={resetEmail}
                onChange={(e) => setResetEmail(e.target.value)}
                required
              />
              <br />
              <br />
              <button type="submit">Send Password Reset Email</button>
              <button type="button" onClick={() => setShowReset(false)}>Back to Login</button>
            </form>
          ) : (
            <form onSubmit={handleEmailSignIn}>
              <input
                type="email"
                placeholder="Enter your email or phone number"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
              <br />
              <br />
              <input
                type="password"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
              <br />
              <br />
              <button type="submit">Continue</button>
            </form>
          )}
          <button className="google-btn" onClick={handleGoogleSignIn}>
            <img src="https://img.icons8.com/color/16/000000/google-logo.png" alt="Google logo" />
            Continue with Google
          </button>
          {!showReset && (
            <a href="#" className="forgot-password-link" onClick={() => setShowReset(true)}>
              Forgot Password?
            </a>
          )}
          <p className="login-footer-text">Don’t have an account? <a href="/signup" className="signup-link">Sign up now</a></p>
        </div>
      </div>
    </div>
  );
};

export default Login;
