import React from 'react';
import { useNavigate } from 'react-router-dom';

const PaymentPage = () => {
  const navigate = useNavigate();

  const handlePaymentSuccess = () => {
    // Payment success logic (you might integrate actual payment here)
    // After successful payment, redirect back to the HR email list page
    navigate('/hr-emails', { state: { paid: true } });
  };

  return (
    <div>
      <h1>Payment Page</h1>
      <p>Please proceed with the payment to unlock the full HR email list.</p>
      <button onClick={handlePaymentSuccess}>Simulate Payment Success</button>
    </div>
  );
};

export default PaymentPage;
