import React, { useEffect, useState } from 'react';
import { signOut, onAuthStateChanged } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import './Navbar.css';
import man from './assets/icons/man.png';
import logo from './assets/icons/HIRE.png';
import { auth } from './firebaseConfig';
import { useCoins } from './CoinContext';

const Navbar = () => {
  const [showNavbar, setShowNavbar] = useState(true);
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [user, setUser] = useState(null);
  const navigate = useNavigate();
  const { coins } = useCoins();

  useEffect(() => {
    let lastScrollY = window.scrollY;

    const handleScroll = () => {
      setShowNavbar(window.scrollY <= lastScrollY);
      lastScrollY = window.scrollY;
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
    });
    return () => unsubscribe();
  }, []);

  const handleUserIconClick = () => {
    if (user) {
      setDropdownVisible(!dropdownVisible);
    } else {
      navigate('/login');
    }
  };

  const handleUpdateResumeClick = () => {
    window.location.href = 'https://hirespoof.com/login-register/';
  };

  const handleLogout = async () => {
    try {
      await signOut(auth);
      navigate('/');
    } catch (error) {
      console.error('Failed to log out', error);
      alert('Failed to log out');
    }
  };

  const handleManageProfileClick = () => {
    window.location.href = 'https://hirespoof.com/profile/';
  };

  const handleLogoClick = () => {
    navigate('/dashboard');
  };

  return (
    <nav className={`navbar ${showNavbar ? 'visible' : 'hidden'}`}>
      <div className="navbar-content">
        <div className="navbar-brand">
          <a href="/combined" onClick={handleLogoClick}>
            <img src={logo} alt="Hirespoof Logo" className="navbar-logo" />
          </a>
        </div>
        <div className="navbar-actions">
          {/* <div className="coin-bar">
            Coins: {coins}
          </div> */}
            {/* <button className="update-resume-button" onClick={handleUpdateResumeClick}>
            Update Resume
          </button> */}
          <div className="navbar-user" onClick={handleUserIconClick}>
            <img src={man} alt="User" className="user-icon" style={{ width: '35px', height: '35px' }} />
            {dropdownVisible && (
              <div className="dropdown-menu">
                {/* <button className="dropdown-item" onClick={handleManageProfileClick}>Profile</button>
                <br />  */}
                <button className="dropdown-item" onClick={handleLogout}>Logout</button>
              </div>
            )}
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
