import React from 'react';
import Navbar from './Navbar'; // Import the Navbar component
import Sidebar from './Sidebar'; // Import the Sidebar component
import './Applied.css';

const appliedInternships = [
  {
    title: 'UI/UX Designer Internship',
    company: 'Nudges | Bangalore, India',
    level: 'Intermediate',
    mode: 'In-Officedddd',
    status: 'Closed',
    applicationDate: '2 May 2024',
    closureDate: '28 May 2024',
  },
  {
    title: 'Product Management Internship',
    company: 'ZuPay | Bangalore, India',
    level: 'Expert',
    mode: 'Work From Home',
    status: 'Closed',
    applicationDate: '30 April 2024',
    closureDate: '16 May 2024',
  },
  {
    title: 'Technical Product Manager Internship',
    company: 'Newton School | Sonipat, India',
    level: 'Beginner',
    mode: 'In-Office',
    status: 'Closed',
    applicationDate: '21 April 2024',
    closureDate: '30 Apr 2024',
  },
];

const appliedJobs = [
  {
    title: 'Software Engineer',
    company: 'ABC Corp | Mumbai, India',
    level: 'Intermediate',
    mode: 'In-Office',
    status: 'Applied',
    applicationDate: '1 May 2024',
    closureDate: 'N/A',
  },
  {
    title: 'Backend Developer',
    company: 'XYZ Ltd | Pune, India',
    level: 'Expert',
    mode: 'Work From Home',
    status: 'Applied',
    applicationDate: '5 May 2024',
    closureDate: 'N/A',
  },
];

const Applied = () => {
  return (
    <div className="applied-page">
      <Navbar /> {/* Add the Navbar component here */}
      <div className="content-container">
        <Sidebar /> {/* Use the Sidebar component here */}
        <div className="main-content">
          <h2>Applied Internships ({appliedInternships.length})</h2>
          <div className="applied-listings">
            {appliedInternships.map((internship, index) => (
              <div className="applied-card" key={index}>
                <h3>{internship.title}</h3>
                <p>{internship.company}</p>
                <p>Level: {internship.level}</p>
                <p>Mode: {internship.mode}</p>
                <p>Status: {internship.status}</p>
                <p>Applied on: {internship.applicationDate}</p>
                <p>Internship Closed on: {internship.closureDate}</p>
                <button>View Details</button>
              </div>
            ))}
          </div>

          <h2>Applied Jobs ({appliedJobs.length})</h2>
          <div className="applied-listings">
            {appliedJobs.map((job, index) => (
              <div className="applied-card" key={index}>
                <h3>{job.title}</h3>
                <p>{job.company}</p>
                <p>Level: {job.level}</p>
                <p>Mode: {job.mode}</p>
                <p>Status: {job.status}</p>
                <p>Applied on: {job.applicationDate}</p>
                <p>Job Closed on: {job.closureDate}</p>
                <button>View Details</button>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Applied;
