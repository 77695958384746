import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import './Sidebar.css';

// Importing icons
import DashboardIcon from './assets/icons/dashboard.png';
import HomeIcon from './assets/icons/home.png';
import FulltimeJobsIcon from './assets/icons/fulltime-jobs.png';
import InternshipsIcon from './assets/icons/internships.png';
import ReferralIcon from './assets/icons/referral.png';
import CandidatesIcon from './assets/icons/candidates.png';
import bag from './assets/icons/bag.png';
import TelegramIcon from './assets/icons/telegram.png';
import AppIcon from './assets/icons/app.png';
import chatbot from './assets/icons/chatbot.png';

const Sidebar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
    <br>
    </br>
    <br>
    </br>
    <button className="toggle-button" onClick={toggleSidebar}>
        ☰
      </button>
      <div className={`sidebar ${isOpen ? 'active' : ''}`}>
        <NavLink to="/" exact activeClassName="active">
          <img src={HomeIcon} alt="Home" className="icon" />
          Home
        </NavLink>
        <NavLink to="/combined" activeClassName="active">
          <img src={DashboardIcon} alt="Dashboard" className="icon" />
          Dashboard
        </NavLink>
        <NavLink to="/fulltime-jobs" activeClassName="active">
          <img src={FulltimeJobsIcon} alt="Fulltime Jobs" className="icon" />
          Fulltime Jobs
        </NavLink>
        <NavLink to="/internships" activeClassName="active">
          <img src={InternshipsIcon} alt="Internships" className="icon" />
          Internships
        </NavLink>

        <a href="/ats-score">
          <img src={ReferralIcon} alt="Get Free Referral For Job" className="icon" />
         Resume ATS Score Checker
        </a>

        <a href="/templates">
          <img src={ReferralIcon} alt="Get Free Referral For Job" className="icon" />
          Cold Mailing Template
        </a>
       <a href="/referral">
          <img src={ReferralIcon} alt="Get Free Referral For Job" className="icon" />
          Get Free Referral For Job
        </a>

        <a href="/hirespoofbot" activeClassName="active">
          <img src={chatbot} alt="Jobs AI Bot" className="icon" />
          AI Job Finder
        </a>
         {/*<a href="https://hirespoof.com/all-candidates/">
          <img src={CandidatesIcon} alt="All Candidates" className="icon" />
          All Candidates
        </a> */}
        <NavLink to="/refer-and-earn" activeClassName="active" className="gradient-button">
          <img src={bag} alt="Refer & Earn" className="icons" />
          Share & Earn
        </NavLink>
        <a href="https://t.me/jobinternalert" target="_blank" rel="noopener noreferrer">
          <img src={TelegramIcon} alt="Join on Telegram" className="icon" />
          Join on Telegram
        </a>
        <a href="https://play.google.com/store/apps/details?id=example.hirespoof.com" target="_blank" rel="noopener noreferrer">
          <img src={AppIcon} alt="Download Hirespoof App" className="icon" />
          Download Hirespoof App
        </a>
        
      </div>
    </>
  );
};

export default Sidebar;
