import React from 'react';
import './Hirespoofbot.css';
import bike from './assets/icons/bike.png'; // Update this path
import Navbar from './Navbar';
import Footer from './Footer';

const Hirespoofbot = () => {
  const handleButtonClick = (url) => {
    window.location.href = url;
  };

  return (
    <div className="random-container">
      <Navbar/>
      
      <header className="random-header">
        <div className="random-nav-bar">
          <nav className="random-nav-links">
            <a href="https://t.me/Hirespoof_gold_bot" className="random-nav-link">Try Now</a>
          </nav>
        </div>
        <div className="random-header-content">
          <h1 className="random-title">AI BOT To help you in search<span className="random-highlight"></span> AI Hack</h1>
          <p className="random-subtitle">Unleash Brainwave's AI potential. Use Hirespoof BOT to get personalised Job & internship alert</p>
          <button className="random-get-started-button" onClick={() => handleButtonClick('https://t.me/Hirespoof_gold_bot')}>Get Started</button>
        </div>
      </header>
      <section className="random-pricing-section">
        <h2 className="random-pricing-title">We like keeping things simple</h2>
        <p className="random-pricing-subtitle">One plan one price.</p>
        <div className="random-pricing-container">
          <p className="random-price">₹2.1<span className="random-price-detail">/Perday</span></p>
          <p className="random-price">₹65<span className="random-price-detail">/Monthly ONLY</span></p>
          <ul className="random-features-list">
            <li>Referral Alert</li>
            <li>Direct Into Your DM's On Telegram</li>
            <li>Finest Jobs & Internship Alert</li>
            <li>20+ Curated Job Alerts</li>
            <li>Focused On Both Fresher & Experienced</li>
            <li>Hassle Free</li>
          </ul>
          <button className="random-trial-button" onClick={() => handleButtonClick('https://t.me/Hirespoof_gold_bot')}>Start your plan</button>
        </div>
      </section>
      <section className="random-connect-section">
        <div className="random-connect-content">
          <img src={bike} alt="AI Chatbot" className="random-connect-image" />
          <div className="random-connect-text">
            <h2 className="random-connect-title">Connect with AI chatbot</h2>
            <p className="random-connect-description">
              Select your experience level and get started              
            </p>
            <button className="random-connect-button" onClick={() => handleButtonClick('https://t.me/Hirespoof_gold_bot')}>Try It Now</button>
          </div>
        </div>
      </section>
      <Footer/>

    </div>
  );
}

export default Hirespoofbot;
