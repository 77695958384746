import React, { useEffect, useState } from 'react';
import Navbar from './components/Navbar';
import './HomePage.css';
import { Link } from 'react-router-dom';
import PartnersSection from './PartnersSection';
import refe from './components/assets/icons/refe.svg';
import ban1 from './components/assets/icons/ban1.gif';
import JobRoles from './JobRoles';  // Import the JobRoles component


const HomePage = () => {
  const [stats, setStats] = useState({
    students: 21881,
    openings: 1000,
    connections: 4500,
    members: 6000,
  });

  useEffect(() => {
    const storedStats = JSON.parse(localStorage.getItem('stats')) || {
      students: 21881,
      openings: 1000,
      connections: 4500,
      members: 6000,
    };

    const newStats = {
      students: storedStats.students + Math.floor(Math.random() * 10 + 1),
      openings: storedStats.openings + Math.floor(Math.random() * 5 + 1),
      connections: storedStats.connections + Math.floor(Math.random() * 7 + 1),
      members: storedStats.members + Math.floor(Math.random() * 5 + 1),
    };

    setStats(newStats);
    localStorage.setItem('stats', JSON.stringify(newStats));
  }, []);

  return (
    <div>
      <Navbar />
      <br />
      <div className="landing-page">
        <div className="homepage">
          <br /><br /><br /><br />
          <h2 className="homepage-subtitle">Not getting right internship & jobs?</h2>
          <h2 className="homepage-title">The #Top Way Students Land Internships & Jobs</h2>
          <p className="homepage-description">
            Why search multiple job platforms when you can find all listings and referrals in one place?
          </p>
          <br />
          <Link to="/internships" className="link">Explore Internships</Link>
          <br />
          <Link to="/signup" className="link">Get Started</Link>
        </div>
        <br /><br />
        <div className="stats-container">
          <div className="stat">
            <h2>{stats.students}+</h2>
            <p>Students</p>
          </div>
          <div className="stat">
            <h2>{stats.openings}+</h2>
            <p>Openings</p>
          </div>
          <div className="stat">
            <h2>{stats.connections}+</h2>
            <p>Connections made</p>
          </div>
          <div className="stat">
            <h2>{stats.members}+</h2>
            <p>Community members</p>
          </div>
        </div>
      </div>
      <a href="/signup">
        <img src={ban1} alt="ban1" className="refe" />
      </a>
      <br /><br /><br /><br />

      <br /><br /><br /><br />


      {/* New Section - Job Roles */}
      <div className="job-roles-section">
        <JobRoles />
      </div>


      <br /><br /><br /><br />
      <br /><br /><br /><br />

      <div className="how-it-helps">
        <h2>How Hirespoof Helps You</h2>
        <div className="help-cards">
          <div className="help-card">
            <span>😎</span>
            <h3>Get Upskilled</h3>
            <p>The #1 way college students & early graduates get Internships, Jobs</p>
          </div>
          <div className="help-card">
            <span>📄</span>
            <h3>Create Profile</h3>
            <p>Create your profile with easy-to-fill form and get recommended with relevant internships/jobs.</p>
          </div>
          <div className="help-card">
            <span>⏰</span>
            <h3>Hassle Free</h3>
            <p>An effortless way to get internships/jobs through a quick and easy application process.</p>
          </div>
          <div className="help-card">
            <span>💰</span>
            <h3>Easy Refferal</h3>
            <p>Use our referral program to get referral from employees working in top startups & MNC's</p>
          </div>
        </div>
        <br /><br />
        <Link to="/signup" className="link">Get Started</Link>
      </div>

      
      <br /><br /><br /><br />
      <br /><br /><br /><br />
      <a href="/signup">
        <img src={refe} alt="refe" className="refe" />
      </a>

    
      <br /><br /><br /><br />
      <br /><br /><br /><br />

      <div className="partners-section">
        <h1>Get internship & Jobs offers from 500+ leading companies</h1>
        <p>Get an opportunity to learn from industry leaders</p>
        <PartnersSection />
      </div>
      <br /><br />
      <footer className="footer">
        <div className="footer-container">
          <div className="footer-logo">
            <img src="https://media.licdn.com/dms/image/D4D0BAQFBVO0-uPBrqg/company-logo_100_100/0/1710000054079?e=1726099200&v=beta&t=d1iEjmJxxdXVIa-XUxABsf5-1ppEm0PvntwbLMLW8Qc" alt="" />
            <p>MADE WITH ❤️ FOR THE GREAT MINDS</p>
            <p>Contact Us: contact@hirespoof.com</p>
          </div>
          <div className="footer-links">
            <div>
              <h3>For Candidates</h3>
              <Link to="/dashboard">Dashboard</Link>
              <Link to="/internships">Internships</Link>
              <Link to="/fulltime-jobs">Fulltime Jobs</Link>
              <Link to="https://referral.hirespoof.com/">Referral For Job</Link>
            </div>
            <div>
              <h3>Helpful Resources</h3>
              <Link to="/">Home</Link>
              <Link to="/referral">Ask For Referral</Link>
            </div>
            <div>
              <h3>Helpful Resources</h3>
              <Link to="/">Home</Link>
              <Link to="/about-us">About Us</Link>
              <Link to="/contact-us">Contact Page</Link>
              <Link to="/disclaimer">Disclaimer</Link>
              <Link to="/privacy-policy">Privacy Policy</Link>
              <Link to="/terms-condition">Terms and Conditions</Link>
            </div>
          </div>
        </div>
        <p>© 2024 Hirespoof. All Right Reserved.</p>
      </footer>
      <div className="landing-page">
        <div className="footer-bottom">
          <b><h1 className="footer-hirespoof">Hirespoof</h1></b>
        </div>
      </div>
    </div>
  );
};

export default HomePage;
