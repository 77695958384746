// src/Pop.js

import React, { useState } from 'react';
import './Pop.css';
import whatsapp from './assets/icons/whatsapp.png'; // Make sure to place this icon in the src folder

function Pop() {
  const [isVisible, setIsVisible] = useState(true);

  const closeBanner = () => {
    setIsVisible(false);
  };

  return (
    isVisible && (
      <div className="pop-up-banner">
        <div className="banner-content">
          <h2>Welcome to Hirespoof!</h2>
          <button className="close-button" onClick={closeBanner}>&times;</button>
          <p>Join our WhatsApp group & Get updates of paid Internships, Jobs, and Live Workshop.</p>
          <a href="https://chat.whatsapp.com/EdMFgke4vlNH8zntwWJ6eQ" target="_blank" rel="noopener noreferrer" className="join-button">
            <img src={whatsapp} alt="WhatsApp" />
            Join Now
          </a>
        </div>
      </div>
    )
  );
}

export default Pop;
