import React from 'react';
import a from './assets/icons/a.jpg';
import b from './assets/icons/b.jpg';
import c from './assets/icons/c.jpg';
import d from './assets/icons/d.jpg';
import e from './assets/icons/e.jpg';
import './Jobs.css';
import Sidebar from './Sidebar';

const Jobs = () => {
  return (
    <div className="jobs-container">
      <Sidebar />
      <div className="jobs-content">
        <h2>Our Stars🎉</h2>
        <div className="job-list">
          <div className="job-item">
            <img src={a} alt="Aditya" />
            <p>Aditya</p>
            <p>Intern: ₹10K/m</p>
          </div>
          <div className="job-item">
            <img src={b} alt="Shruti Singh" />
            <p>Shruti Singh</p>
            <p>Intern: ₹20K/m</p>
          </div>
          <div className="job-item">
            <img src={c} alt="Kaustubh Gupta" />
            <p>Kaustubh Gupta</p>
            <p>Intern: ₹24K/m</p>
          </div>
          <div className="job-item">
            <img src={d} alt="Jay Gohil" />
            <p>Kashish</p>
            <p>Intern: ₹18K/m</p>
          </div>
          
          <div className="job-item">
            <img src={e} alt="Yog Dharaskar" />
            <p>Yog Dharaskar</p>
            <p>Intern: ₹40K/m</p>
          </div>
        </div>
        <br>
        </br>
        <div className="int">
        <a href="/internships">
        <center><h1>Want to Be in this list</h1></center> 
        <center><button>Apply For internships Now</button></center>
        </a>
        </div>
      </div>
    </div>
  );
};

export default Jobs;
