import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { signInWithGoogle, auth, db } from './components/firebaseConfig';
import { createUserWithEmailAndPassword } from "firebase/auth";
import { doc, getDoc, setDoc, updateDoc } from "firebase/firestore";
import './AuthStyles.css';  // Import the CSS file

const SignUp = () => {
  const navigate = useNavigate();

  const [username, setUsername] = useState('');
  const [fullName, setFullName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [referralCode, setReferralCode] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [passwordMessage, setPasswordMessage] = useState('');

  const handleGoogleSignUp = async () => {
    try {
      await signInWithGoogle();
      alert('Signed up with Google');
      navigate('/internships');
    } catch (error) {
      console.error(error);
      alert('Failed to sign up with Google');
    }
  };

  const handlePasswordChange = (e) => {
    const newPassword = e.target.value;
    setPassword(newPassword);
    setPasswordMessage(checkPasswordStrength(newPassword));
  };

  const checkPasswordStrength = (password) => {
    const minLength = 8;
    const hasUpperCase = /[A-Z]/.test(password);
    const hasLowerCase = /[a-z]/.test(password);
    const hasNumbers = /\d/.test(password);
    const hasNonalphas = /\W/.test(password);
    if (password.length < minLength || !hasUpperCase || !hasLowerCase || !hasNumbers || !hasNonalphas) {
      return 'Weak password. Try a stronger one (min 8 chars, mix of upper/lower case, numbers, symbols).';
    }
    return '';
  };

  const handleEmailSignUp = async (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      alert('Passwords do not match');
      return;
    }
    if (passwordMessage) {
      alert('Please enter a stronger password');
      return;
    }
    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const userId = userCredential.user.uid;

      await setDoc(doc(db, 'users', userId), {
        username,
        fullName,
        email,
        phoneNumber,
        coins: 0 
      });

      if (referralCode) {
        const referrerDoc = await getDoc(doc(db, 'users', referralCode));
        if (referrerDoc.exists()) {
          const referrerData = referrerDoc.data();
          await updateDoc(doc(db, 'users', referralCode), {
            coins: (referrerData.coins || 0) + 5
          });
        }
      }

      alert('Account created successfully');
      navigate('/internships');
    } catch (error) {
      console.error(error);
      alert('Failed to create account');
    }
  };

  return (
    <div className="auth-container">
      <div className="auth-image">
        {/* Background image will be set via CSS */}
      </div>
      <div className="auth-form-container">
        <div className="auth-form">
          <h3><right>Welcome to Hirespoof</right></h3>
          <h2>Right Path To End Your Internship Search</h2>
          <form onSubmit={handleEmailSignUp}>
            <input
              type="text"
              placeholder="Username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              required
            />
            <br />
            <br />
            <input
              type="text"
              placeholder="Full Name"
              value={fullName}
              onChange={(e) => setFullName(e.target.value)}
              required
            />
            <br />
            <br />
            <input
              type="email"
              placeholder="john@gmail.com"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            <br />
            <br />
            <input
              type="password"
              placeholder="Password"
              value={password}
              onChange={handlePasswordChange}
              required
            />
            {passwordMessage && <p style={{ color: 'red' }}>{passwordMessage}</p>}
            <br />
            <br />
            <input
              type="password"
              placeholder="Confirm Password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              required
            />
            <br />
            <br />
            <input
              type="text"
              placeholder="Referral Code (Optional)"
              value={referralCode}
              onChange={(e) => setReferralCode(e.target.value)}
            />
            <br />
            <br />
            <input
              type="tel"
              placeholder="Phone Number"
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
              required
            />
            <br />
            <br />
            <button type="submit">Create Account</button>
          </form>
          <br />
          <p1>or</p1>
          <button className="google-btn" onClick={handleGoogleSignUp}>
            <img src="https://img.icons8.com/color/16/000000/google-logo.png" alt="Google logo" />
            Continue with Google
          </button>
          <p className="login-footer-text">Already have an account? <a href="/login" className="signup-link">Login</a></p>
          <p1>By continuing you agree to our privacy policy and terms of use</p1>
        </div>
      </div>
    </div>
  );
};

export default SignUp;
