import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom'; // For navigation
import './ResumeScan.css'; // Assuming you'll add a CSS file for styling
import Navbar from './Navbar';

const ResumeScan = () => {
    const [resumeUploaded, setResumeUploaded] = useState(false);
    const [fileName, setFileName] = useState('');
    const [fileContent, setFileContent] = useState(null);
    const [experience, setExperience] = useState('');
    const navigate = useNavigate(); // Hook to handle navigation

    // Handle resume file upload
    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setFileName(file.name);
            const reader = new FileReader();
            reader.onload = (event) => {
                setFileContent(event.target.result);
            };
            reader.readAsText(file); // Reading the file content
            setResumeUploaded(true); // Indicate resume has been uploaded
        }
    };

    // Handle experience dropdown selection
    const handleExperienceChange = (e) => {
        setExperience(e.target.value);
    };

    // Submit the resume for scanning and get ATS score from Gemini API
    const handleSubmit = (e) => {
        e.preventDefault();
        if (resumeUploaded && experience) {
            // Call Gemini API to get ATS score
            fetch(`https://generativelanguage.googleapis.com/v1beta/models/gemini-1.5-flash-latest:generateContent?key=${process.env.REACT_APP_GEMINI_API_KEY}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    contents: [
                        {
                            parts: [
                                {
                                    text: `Analyze the resume and provide an ATS score for a resume with ${experience} experience. Resume content: ${fileContent}`
                                }
                            ]
                        }
                    ]
                }),
            })
            .then(response => response.json())
            .then(data => {
                const atsScore = extractAtsScoreFromGeminiResponse(data);
                navigate('/ats-score', { state: { score: atsScore, experience } }); // Navigate to ATS score page with data
            })
            .catch(error => {
                console.error('Error fetching the ATS score from Gemini API:', error);
            });
        } else {
            alert('Please upload your resume and select your experience.');
        }
    };

    // Function to extract ATS score from Gemini API response
    const extractAtsScoreFromGeminiResponse = (data) => {
        // Extract score from the response text (assuming it returns a number)
        return data?.contents?.[0]?.parts?.[0]?.text.match(/\d+/)?.[0] || 0;  // Example: extract score from text
    };

    return (
        <div className="resume-scan-container">
            <div className="main-content">
                <Navbar />

                <div className="headerr">
                    <div className="avatar"></div>
                    <div className="welcome-text">
                        <span>Hello 👋</span>
                        <p>Know your Resume ATS Score & all the Mistakes: Don't Let Applicant Tracking Systems Reject your Resume</p>
                        <p>Used by 1,00,000+ Jobseekers across the globe!</p>
                    </div>
                </div>

                <div className="resume-upload-section">
                    <h2>Get your Resume ATS Score and Mistakes</h2>
                    <p>10 credits = 1 Resume Score + Mistakes</p>
                    <p>0 credits = Free Resume Score</p>

                    {!resumeUploaded ? (
                        <div className="upload-box">
                            <label htmlFor="file-upload" className="choose-file-button">
                                Choose File
                            </label>
                            <input
                                id="file-upload"
                                type="file"
                                style={{ display: 'none' }}
                                accept=".pdf"
                                onChange={handleFileChange}
                            />
                            <p>Upload your resume here (pdf up to 2MB)</p>
                        </div>
                    ) : (
                        <div className="upload-success">
                            <h3>Resume Uploaded</h3>
                            <p>{fileName}</p>
                            <label htmlFor="experience">Please Enter Your Experience:</label>
                            <select
                                id="experience"
                                value={experience}
                                onChange={handleExperienceChange}
                                className="experience-dropdown"
                            >
                                <option value="">Select Your Experience</option>
                                <option value="Fresher">Fresher (No full-time experience)</option>
                                <option value="Less than 2 years">Less than 2 years</option>
                                <option value="2-5 years">2-5 years</option>
                                <option value="5+ years">5+ years</option>
                            </select>
                            <button className="submit-button" onClick={handleSubmit}>
                                Submit
                            </button>
                        </div>
                    )}

                    <p className="disclaimer">
                        Disclaimer - You can also upload your resume with Zero (0) Credits to get a FREE Resume ATS Score.
                    </p>
                </div>
            </div>

            <div className="footerr">
                <div className="credits-section">
                    <span>Credits Available : 0</span>
                    <button className="buy-credits-button">BUY MORE CREDITS</button>
                </div>
                <div className="whatsapp-icon">
                    {/* Insert WhatsApp icon here */}
                </div>
            </div>
        </div>
    );
};

export default ResumeScan;
