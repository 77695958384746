// src/components/AboutUs.js
import React from 'react';
import './AboutUs.css';
import Navbar from './Navbar';
const AboutUs = () => {
  return (
    <div className="about-us">
        <Navbar/>
        <br>
        </br>
        <br>
        </br>
      <h1>About Us</h1>
      <p>Not getting the right internship & jobs? The #1 way college students & early graduates get Internships, Jobs</p>
      <p>Why search across multiple job platforms when you can find all the listings & referrals in one place?</p>
  

      <div className="statistics">
        <div>
          <h2>16129+</h2>
          <p>Students</p>
        </div>
        <div>
          <h2>1066+</h2>
          <p>Openings</p>
        </div>
        <div>
          <h2>4583+</h2>
          <p>Connections made</p>
        </div>
        <div>
          <h2>5074+</h2>
          <p>Community members</p>
        </div>
      </div>

      <h2>How Hirespoof Helps You</h2>
      <div className="features">
        <div>
          <span role="img" aria-label="upskilled">😎</span>
          <p>Get Upskilled<br/>The #1 way college students & early graduates get Internships, Jobs</p>
        </div>
        <div>
          <span role="img" aria-label="create profile">📄</span>
          <p>Create Profile<br/>Create your profile with an easy-to-fill form and get recommended with relevant internships/jobs.</p>
        </div>
        <div>
          <span role="img" aria-label="hassle free">⏰</span>
          <p>Hassle Free<br/>An effortless way to get internships/jobs through a quick and easy application process.</p>
        </div>
        <div>
          <span role="img" aria-label="easy referral">💰</span>
          <p>Easy Referral<br/>Use our referral program to get referrals from employees working in top startups & MNC's</p>
        </div>
      </div>

      <p>Get internship & job offers from 500+ leading companies. Get an opportunity to learn from industry leaders.</p>
    </div>
  );
};

export default AboutUs;
