import React from 'react';
import Navbar from './components/Navbar'; // Import the Navbar component
import Sidebar from './components/Sidebar'; // Import the Sidebar component
import Trending from './components/Trending'; // Import the Trending component
import Jobs from './components/Jobs'; // Import the Jobs component
import './Dashboard.css';

const Dashboard = () => {
  return (
    <div className="dashboard">
      <Navbar />
      <div className="content-container">
        <Sidebar />
        <div className="main-content">
          <Trending />
          <Jobs />
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
