import React from 'react';
import './JobRoles.css';
//import epicorLogo from './components/assets/icons/epicorLogo.png';
import unileverLogo from './components/assets/icons/unileverLogo.png';
import barclaysLogo from './components/assets/icons/barclaysLogo.jpg';
import nokiaLogo from './components/assets/icons/nokiaLogo.jpeg';
import amazonLogo from './components/assets/icons/amazonLogo.png';
import flipkartLogo from './components/assets/icons/flipkartLogo.png';
import microsoftLogo from './components/assets/icons/microsoftLogo.png';
import walmartLogo from './components/assets/icons/walmartLogo.png';
import ciscoLogo from './components/assets/icons/ciscoLogo.jpeg';
import jpmorganLogo from './components/assets/icons/jpmorganLogo.png';
import meeshoLogo from './components/assets/icons/meeshoLogo.png';
//import mastercardLogo from './components/assets/icons/mastercardLogo.png';
import deliveryLogo from './components/assets/icons/deliveryLogo.png';
import googleLogo from './components/assets/icons/googleLogo.jpg';
import titanLogo from './components/assets/icons/titanLogo.jpg';
//import paypalLogo from './components/assets/icons/paypalLogo.jpg';

const JobRoles = () => {
  const roles = [
    {
      title: 'Data Analyst',
      openings: '370+ Openings',
      companies: [unileverLogo, barclaysLogo, nokiaLogo],
      link: '/internships' // Example link, update with actual link later
    },
    {
      title: 'Frontend',
      openings: '250+ Openings',
      companies: [amazonLogo, flipkartLogo, microsoftLogo, walmartLogo],
      link: '/internships' // Example link, update with actual link later
    },
    {
      title: 'Full-Stack Roles',
      openings: '300+ Openings',
      companies: [ciscoLogo, jpmorganLogo, meeshoLogo],
      link: '/internships' // Example link, update with actual link later
    },
    {
      title: 'Product Management',
      openings: '246+ Openings',
      companies: [deliveryLogo, googleLogo, titanLogo],
      link: '/internships' // Example link, update with actual link later
    },
  ];

  return (
    <div className="job-roles-container">
      <h1>Find The Right Role For You</h1>
      <p>Apply to roles matching your skills from 500+ trending options.</p>
      <div className="roles-list">
        {roles.map((role, index) => (
          <a href={role.link} className="role-card" key={index}>
            <h2>{role.title}</h2>
            <p>{role.openings}</p>
            <div className="company-logos">
              {role.companies.map((logo, idx) => (
                <img src={logo} alt="Company logo" key={idx} />
              ))}
            </div>
          </a>
        ))}
      </div>
    </div>
  );
};

export default JobRoles;
