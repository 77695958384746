import React from 'react';
import a from './assets/icons/a.jpg';
import b from './assets/icons/b.jpg';
import c from './assets/icons/c.jpg';
import d from './assets/icons/d.jpg';
import e from './assets/icons/e.jpg';
import cor from './assets/icons/cor.png';
import mer from './assets/icons/mer.png';
import './CombinedPage.css'; // Import the combined CSS file
import Sidebar from './Sidebar';
import Navbar from './Navbar';
import Footer from './Footer';

const CombinedPage = () => {
  return (
    <div className="combined-container">
      <Sidebar />
      <div className="combined-content">
        <Navbar/>
        <br></br>

        <div className="jobs-section">
          <h2><center>Our Stars🎉</center></h2>
          <div className="job-list">
            <div className="job-item">
              <img src={a} alt="Aditya" />
              <p>Aditya</p>
              <p>Intern: ₹10K/m</p>
            </div>
            <div className="job-item">
              <img src={b} alt="Shruti Singh" />
              <p>Shruti Singh</p>
              <p>Intern: ₹20K/m</p>
            </div>
            <div className="job-item">
              <img src={c} alt="Kaustubh Gupta" />
              <p>Kaustubh Gupta</p>
              <p>Intern: ₹24K/m</p>
            </div>
            <div className="job-item">
              <img src={d} alt="Jay Gohil" />
              <p>Kashish</p>
              <p>Intern: ₹18K/m</p>
            </div>
            <div className="job-item">
              <img src={e} alt="Yog Dharaskar" />
              <p>Yog Dharaskar</p>
              <p>Intern: ₹40K/m</p>
            </div>
          </div>
          <div className="int">
            <a href="/internships">
              <h1>Want to Be in this list</h1>
              <button>Apply For internships Now</button>
            </a>
          </div>
        </div>

        <div className="trending-section">
          <div className="trending-item-container">
            <h2>Google Data Analytics Professional Certificate</h2>
            <a href="https://linksredirect.com/?cid=214566&source=linkkit&url=https%3A%2F%2Fwww.coursera.org%2Fprofessional-certificates%2Fgoogle-data-analytics" target="_blank" rel="noopener noreferrer">
              <img src={cor} alt="Google Data Analytics Professional Certificate" />
            </a>
            <p>Learn from top mentors...</p>
            <a href="https://linksredirect.com/?cid=214566&source=linkkit&url=https%3A%2F%2Fwww.coursera.org%2Fprofessional-certificates%2Fgoogle-data-analytics" target="_blank" rel="noopener noreferrer">
              <button>View Details</button>
            </a>
          </div>
          <div className="trending-item-container">
            <h2>Learning MEAN Stack by Building Real world Application</h2>
            <a href="https://linksredirect.com/?cid=214567&source=linkkit&url=https%3A%2F%2Fwww.coursera.org%2Fspecializations%2Fmean-stack" target="_blank" rel="noopener noreferrer">
              <img src={mer} alt="Learning MEAN Stack by Building Real world Application Specialization" />
            </a>
            <p>Learn from top mentors...</p>
            <a href="https://linksredirect.com/?cid=214567&source=linkkit&url=https%3A%2F%2Fwww.coursera.org%2Fspecializations%2Fmean-stack" target="_blank" rel="noopener noreferrer">
              <button>View Details</button>
            </a>
          </div>

        </div>
      </div>
    </div>
  );
};

export default CombinedPage;
