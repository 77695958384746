import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import './PersonDetails.css';
import personsData from './personsData'; // Import the personsData
import AdSenseComponent from './AdSenseComponent'; // Import AdSenseComponent

const PersonDetails = () => {
  const { id } = useParams();
  const person = personsData.find(p => p.id === parseInt(id));
  const [showLoginSignupModal, setShowLoginSignupModal] = useState(false);

  useEffect(() => {
    // Check if user has already logged in or signed up
    const isLoggedInOrSignedUp = localStorage.getItem('isLoggedInOrSignedUp');
    if (!isLoggedInOrSignedUp) {
      // Show login/signup modal after 5 seconds
      const modalTimeout = setTimeout(() => {
        setShowLoginSignupModal(true);
      }, 5000);
      
      return () => clearTimeout(modalTimeout);
    }
  }, []);

  const handleLoginSignup = () => {
    // Set flag in localStorage indicating that user has logged in or signed up
    localStorage.setItem('isLoggedInOrSignedUp', 'true');
    // Redirect user to LinkedIn
    window.location.href = 'https://www.linkedin.com'; // Replace with LinkedIn URL
  };

  if (!person) {
    return <div>Person not found</div>;
  }

  return (
    <div className="person-details">
      {/* Login/Signup Modal */}
      {showLoginSignupModal && (
        <div className="modal-overlay">
          <div className="modal">
            <h2>Sign up or log in to continue</h2>
            <p>Please sign up or log in to continue to ask for a referral.</p>
            <div className="modal-buttons">
              <button className="modal-button" onClick={handleLoginSignup}>Sign Up / Log In</button>
            </div>
          </div>
        </div>
      )}

      <img src={person.profilePic} alt="Profile" className="profile-img" />
      <h2>{person.name}</h2>
      <p>{person.position} at {person.company}</p>
      <iframe 
        src={person.linkedinPostUrl}
        height="700"
        width="400"
        frameBorder="0"
        allowFullScreen=""
        title="Embedded post"
      ></iframe>


      {/* Disable "Connect on LinkedIn" button if modal is shown */}
      {!showLoginSignupModal && (
        <a href={person.linkedinProfileUrl} target="_blank" rel="noopener noreferrer" className="connect-btn">
          Connect on LinkedIn
        </a>
      )}
      <h3>More People Hiring for Same Position</h3>
      {/* Rest of the content */}
    </div>
  );
};

export default PersonDetails;
