import React from 'react';
import './PartnersSection.css'; // Create and import the CSS file

const PartnersSection = () => {
  const logos = [
    'https://static.ambitionbox.com/alpha/company/photos/logos/zepto.jpg',
    'https://static.ambitionbox.com/assets/v2/images/rs:fit:200:200:false:false/bG9jYWw6Ly8vbG9nb3Mvb3JpZ2luYWxzL3BvcnRlci5qcGc.webp',
    'https://static.ambitionbox.com/assets/v2/images/rs:fit:200:200:false:false/bG9jYWw6Ly8vbG9nb3Mvb3JpZ2luYWxzL2JsaW5raXQuanBn.webp',
    'https://static.ambitionbox.com/assets/v2/images/rs:fit:200:200:false:false/bG9jYWw6Ly8vbG9nb3Mvb3JpZ2luYWxzL25vYnJva2VyLmpwZw.webp',
    'https://static.ambitionbox.com/alpha/company/photos/logos/dunzo-188560.jpg',
    'https://static.ambitionbox.com/alpha/company/photos/logos/rupeek.jpg',
    'https://static.ambitionbox.com/assets/v2/images/rs:fit:200:200:false:false/bG9jYWw6Ly8vbG9nb3Mvb3JpZ2luYWxzL3pvbG8uanBn.webp',
    'https://static.ambitionbox.com/alpha/company/photos/logos/lido-learning.jpg',
    'https://static.ambitionbox.com/assets/v2/images/rs:fit:200:200:false:false/bG9jYWw6Ly8vbG9nb3Mvb3JpZ2luYWxzL2hvdXNpbmcuanBn.webp',
    'https://static.ambitionbox.com/assets/v2/images/rs:fit:200:200:false:false/bG9jYWw6Ly8vbG9nb3Mvb3JpZ2luYWxzL3Jhem9ycGF5LmpwZw.webp',
    'https://static.ambitionbox.com/alpha/company/photos/logos/dehaat.jpg',
    'https://static.ambitionbox.com/alpha/company/photos/logos/cuelearn.jpg',
    'https://static.ambitionbox.com/assets/v2/images/rs:fit:200:200:false:false/bG9jYWw6Ly8vbG9nb3Mvb3JpZ2luYWxzL29kYS1jbGFzcy5qcGc.webp',
    'https://static.ambitionbox.com/assets/v2/images/rs:fit:200:200:false:false/bG9jYWw6Ly8vbG9nb3Mvb3JpZ2luYWxzL2Fja28tZ2VuZXJhbC1pbnN1cmFuY2UuanBn.webp',
    'https://static.ambitionbox.com/alpha/company/photos/logos/scaler-academy.jpg',
    'https://static.ambitionbox.com/alpha/company/photos/logos/keysight-technologies.jpg',
    'https://static.ambitionbox.com/alpha/company/photos/logos/cuelearn.jpg',
    'https://static.ambitionbox.com/assets/v2/images/rs:fit:200:200:false:false/bG9jYWw6Ly8vbG9nb3Mvb3JpZ2luYWxzL3pvb21jYXIuanBn.webp',
    'https://static.ambitionbox.com/assets/v2/images/rs:fit:200:200:false:false/bG9jYWw6Ly8vbG9nb3Mvb3JpZ2luYWxzL2xvYWRzaGFyZS1uZXR3b3Jrcy5qcGc.webp',
    'https://static.ambitionbox.com/alpha/company/photos/logos/examity.jpg',
    'https://static.ambitionbox.com/assets/v2/images/rs:fit:200:200:false:false/bG9jYWw6Ly8vbG9nb3Mvb3JpZ2luYWxzL3RoZW1hdGhjb21wYW55LmpwZw.webp',
    'https://static.ambitionbox.com/assets/v2/images/rs:fit:200:200:false:false/bG9jYWw6Ly8vbG9nb3Mvb3JpZ2luYWxzL3JhcGlkby5qcGc.webp',
    'https://static.ambitionbox.com/assets/v2/images/rs:fit:200:200:false:false/bG9jYWw6Ly8vbG9nb3Mvb3JpZ2luYWxzL2xlYXAtaW5kaWEuanBn.webp',
    'https://static.ambitionbox.com/alpha/company/photos/logos/arzooo-dot-com.jpg',
    'https://static.ambitionbox.com/assets/v2/images/rs:fit:200:200:false:false/bG9jYWw6Ly8vbG9nb3Mvb3JpZ2luYWxzL2Z5bmQuanBn.webp',
    'https://static.ambitionbox.com/assets/v2/images/rs:fit:200:200:false:false/bG9jYWw6Ly8vbG9nb3Mvb3JpZ2luYWxzL2lubm92YWNjZXIuanBn.webp',
    'https://static.ambitionbox.com/alpha/company/photos/logos/nestaway-326360.jpg',
    'https://static.ambitionbox.com/alpha/company/photos/logos/noon.jpg',
    'https://static.ambitionbox.com/alpha/company/photos/logos/simply-vyapar-apps.jpg',
    'https://static.ambitionbox.com/assets/v2/images/rs:fit:200:200:false:false/bG9jYWw6Ly8vbG9nb3Mvb3JpZ2luYWxzL2JsdWVzdG9uZWNvbS5qcGc.webp',
    'https://static.ambitionbox.com/alpha/company/photos/logos/housejoy-609.jpg',
    'https://static.ambitionbox.com/alpha/company/photos/logos/hungerbox-172139.jpg',
    'https://static.ambitionbox.com/assets/v2/images/rs:fit:200:200:false:false/bG9jYWw6Ly8vbG9nb3Mvb3JpZ2luYWxzL2N1cmUtZm9vZHMuanBn.webp',
    'https://static.ambitionbox.com/assets/v2/images/rs:fit:200:200:false:false/bG9jYWw6Ly8vbG9nb3Mvb3JpZ2luYWxzL3RlYWNobWludC5qcGc.webp',
    'https://static.ambitionbox.com/alpha/company/photos/logos/futwork.jpg',
    'https://static.ambitionbox.com/assets/v2/images/rs:fit:200:200:false:false/bG9jYWw6Ly8vbG9nb3Mvb3JpZ2luYWxzL2tyYXp5YmVlLmpwZw.webp',
    'https://static.ambitionbox.com/assets/v2/images/rs:fit:200:200:false:false/bG9jYWw6Ly8vbG9nb3Mvb3JpZ2luYWxzL2xlYXAtZmluYW5jZS5qcGc.webp',
    'https://static.ambitionbox.com/assets/v2/images/rs:fit:200:200:false:false/bG9jYWw6Ly8vbG9nb3Mvb3JpZ2luYWxzL3pldGEuanBn.webp',
    'https://static.ambitionbox.com/assets/v2/images/rs:fit:200:200:false:false/bG9jYWw6Ly8vbG9nb3Mvb3JpZ2luYWxzL3p5cHAtZWxlY3RyaWMuanBn.webp',
    'https://static.ambitionbox.com/alpha/company/photos/logos/upwork.jpg',
    'https://static.ambitionbox.com/assets/v2/images/rs:fit:200:200:false:false/bG9jYWw6Ly8vbG9nb3Mvb3JpZ2luYWxzL2dyb3d3LmpwZw.webp',
    'https://static.ambitionbox.com/alpha/company/photos/logos/letstransport-397152.jpg',
    'https://static.ambitionbox.com/alpha/company/photos/logos/dotpe.jpg',
    'https://static.ambitionbox.com/alpha/company/photos/logos/ketto-305139.jpg',
    'https://static.ambitionbox.com/assets/v2/images/rs:fit:200:200:false:false/bG9jYWw6Ly8vbG9nb3Mvb3JpZ2luYWxzL2hlcm8taG91c2luZy1maW5hbmNlLmpwZw.webp',
     'https://production-cuvette.s3.ap-south-1.amazonaws.com/company/626bbc841930ce2f2ac70aba/logo.jpg?d=1651228598307',
     'https://production-cuvette.s3.ap-south-1.amazonaws.com/company/663f64076f823644921a53e6/logo.jpg?d=1715430976029',
     'https://production-cuvette.s3.ap-south-1.amazonaws.com/company/6627c720736a84dd67bea4c6/logo.jpg?d=1713883287961',
     'https://production-cuvette.s3.ap-south-1.amazonaws.com/company/65b8a27c3714ff95754e7e8c/logo.jpg?d=1706599285967',
     'https://d8it4huxumps7.cloudfront.net/uploads/images/150x150/uploadedManual-666c207bd5594_download.jpg?d=200x200',
     'https://d8it4huxumps7.cloudfront.net/uploads/images/150x150/6671358968211_organisation_image-eqKMbjJmCm1721986993Rk6J3zMKh8.png?d=200x200',
     'https://d8it4huxumps7.cloudfront.net/uploads/images/150x150/6679022c72a2b_clootrack_logo.png?d=200x200',
     'https://d8it4huxumps7.cloudfront.net/uploads/images/150x150/667806d522d6b_organisation_image-ZEPSdTsvPu778484680NRBkczn18J.png?d=200x200',
     'https://photos.wellfound.com/startups/i/10020775-bb0a57074d7da8de5bf387b2edb7ecac-medium_jpg.jpg?buster=1709530128',
     'https://photos.wellfound.com/startups/i/10082168-46cd01a4750e405babe92f79d30a85c9-medium_jpg.jpg?buster=1713944208',
     'https://production-cuvette.s3.ap-south-1.amazonaws.com/company/667306b7a8c0dc444bce2856/logo.jpg?d=1718814534616',
'https://photos.wellfound.com/startups/i/8177269-3d741e596242fcb6aeed752f1fe1b8b1-medium_jpg.jpg?buster=1689918891',
     'https://production-cuvette.s3.ap-south-1.amazonaws.com/company/6446c5d307414f20c61cce3a/logo.jpg?d=1706243313319',
     'https://production-cuvette.s3.ap-south-1.amazonaws.com/company/665cedb762456b093d7a4851/logo.jpg?d=1717366693803',
  'https://photos.wellfound.com/startups/i/7103781-67d43cf192f7f5d5bfdbc1cf1210327a-medium_jpg.jpg?buster=1584240793',
  'https://photos.wellfound.com/startups/i/9011556-7cad99411ba3a44661378e0cc044a25d-medium_jpg.jpg?buster=1681571884',
   'https://photos.wellfound.com/startups/i/8873963-f685fe77d20a16c42fa9752ff4ec2c1c-medium_jpg.jpg?buster=1710908459',
   'https://production-cuvette.s3.ap-south-1.amazonaws.com/company/6674031b1c985a36c5e955f3/logo.jpg?d=1718879233310',
  'https://production-cuvette.s3.ap-south-1.amazonaws.com/company/625ea25cedd7b61bff923e6e/logo.jpg?d=1675676727327',  
  'https://production-cuvette.s3.ap-south-1.amazonaws.com/company/63c8cadb17794480f65e0dc9/logo.jpg?d=1674103815030',
  'https://production-cuvette.s3.ap-south-1.amazonaws.com/company/644e12115c8d4bbd4bb5da33/logo.jpg?d=1682838127018',
  'https://media.licdn.com/dms/image/D4D0BAQFWpQubqLLomA/company-logo_200_200/0/1717591942553?e=2147483647&v=beta&t=Cf7Gt5ABPGGB_SK7n_h7LeiNzduZkYzzClKIuHs4dvM',
  'https://media.licdn.com/dms/image/C4E0BAQFUlf5Ssuom4g/company-logo_100_100/0/1630643688890?e=2147483647&v=beta&t=bpf8QpqKczxQE-LHKS3dzZR_k7QaqQgl8ZLw0WOSNrg',
  'https://media.licdn.com/dms/image/C560BAQG74WgLiqYpfw/company-logo_100_100/0/1630648659189/syscore_solutions_pvt_ltd_logo?e=2147483647&v=beta&t=HaVRHTlh4U9TAiId-TsFJwfl90L8uV2nY4PgeHawciQ',
  'https://d8it4huxumps7.cloudfront.net/uploads/images/150x150/uploadedManual-666938eebc6ea_stoxx_logo.jpeg?d=200x200',
  'https://d8it4huxumps7.cloudfront.net/uploads/images/150x150/65f001cbe322e_organisation_image-xDkDiVwGeK12281376499UI0lmfuwx.png?d=200x200',
  'https://d8it4huxumps7.cloudfront.net/uploads/images/150x150/uploadedManual-662a31d0862d4_img-20230817-wa0011.jpg?d=200x200',
  'https://d8it4huxumps7.cloudfront.net/uploads/images/150x150/uploadedManual-660bd2d53a80b_download__1_.png?d=200x200',

];

  return (
    <div className="partners-section">
      <div className="partners-logos">
        {logos.map((logo, index) => (
          <img key={index} src={logo} alt={`Company ${index + 1}`} />
        ))}
      </div>
    </div>
  );
};

export default PartnersSection;
