import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import './Home.css';
import Footer from './Footer';
import personsData from './personsData'; // Import the personsData
//import { Analytics } from "@vercel/analytics/react";
import Modal from './Modal'; // Import the Modal component
import AdSenseComponent from './AdSenseComponent'; // Import AdSenseComponent

const Home = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const persons = personsData;

  useEffect(() => {
    // Set isVisible to true after a short delay to trigger the animation
    const timeout = setTimeout(() => {
      setIsVisible(true);
    }, 500); // Adjust the delay as needed

    // Clear timeout to avoid memory leaks
    return () => clearTimeout(timeout);
  }, []);

  useEffect(() => {
    const hasSignedUp = localStorage.getItem('hasSignedUp');
    if (!hasSignedUp) {
      // Show modal after 5 seconds
      const modalTimeout = setTimeout(() => {
        setShowModal(true);
      }, 5000);

      // Clear timeout to avoid memory leaks
      return () => clearTimeout(modalTimeout);
    }
  }, []);

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleSignupOrLogin = () => {
    localStorage.setItem('hasSignedUp', 'true');
    window.location.href = 'https://hirespoof.com/login-register/';
  };

  return (
    <div className={`home ${isVisible ? 'fade-in' : ''}`}>
      <header className="home-header">
        <h1 className="desktop-title">Take The Referral Get The Job</h1>
        <h2 className="mobile-title">Take The Referral Get The Job</h2>
        <h5>Connect With Hiring Managers Directly</h5>
      </header>
      
      <input type="text" placeholder="Type Company/Position" style={{ display: 'none' }} />
      <button className="search-btn" style={{ display: 'none' }}>Search</button>
      
      <div className="data-container">
        {persons.map((person, index) => (
          <React.Fragment key={person.id}>
            <div className="data-item">
              <img 
                src={person.profilePic} 
                alt="Profile" 
                className="profile-img"
              />
              <div className="person-info">
                <h3>{person.name}</h3>
                <p>{person.position} at {person.company}</p>
              </div>
              <Link to={`/details/${person.id}`} className="view-post-btn">
                View Hiring Posts
              </Link>
            </div>
           {/* <div className="ad-container">
          <AdSenseComponent />
        </div> */}
          </React.Fragment>
        ))}
        <div className="video-container">
          <video controls autoPlay muted className="promo-video">
            <source src="/HIRESPOOF.mp4" type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
        <div className="apply-now-container">
          <a href="https://hirespoof.com/jobs-list/?filter-orderby=newest&paged=1&filter-title=internships" target="_blank" rel="noopener noreferrer" className="apply-now-btn">
            APPLY NOW
          </a>
        </div>
       {/* <div className="ad-container">
          <AdSenseComponent />
        </div> */}
        <br />
      </div>
      <Footer />
      <Modal show={showModal} onClose={handleCloseModal} showCloseButton={true} onSignupOrLogin={handleSignupOrLogin} />
    </div>
  );
};

export default Home;
