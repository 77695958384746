import React, { createContext, useState, useContext } from 'react';

// Creating a context to manage coins state
const CoinContext = createContext();

// Custom hook to use the coins context
export const useCoins = () => useContext(CoinContext);

// Provider component to wrap your application and provide the coins state
export const CoinProvider = ({ children }) => {
  const [coins, setCoins] = useState(0);

  return (
    <CoinContext.Provider value={{ coins, setCoins }}>
      {children}
    </CoinContext.Provider>
  );
};
