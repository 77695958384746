import React, { useState } from 'react';
import './HrEmailList.css'; // Import the updated CSS file
import Navbar from './Navbar';
import AdSenseComponent from './AdSenseComponent';

const HrEmailList = () => {
  const [isPaid, setIsPaid] = useState(false);

  // Dummy HR email and name list
  const hrList = [
    { name: "Akanksha Puri", email: "akanksha.puri@sourcefuse.com", role: "Associate Director HR", company: "SourceFuse Technologies" },
    { name: "Akanksha Sogani", email: "akanksha.sogani@perennialsys.com", role: "Head HR", company: "Perennial Systems" },
    { name: "Akhil Jogiparthi", email: "akhil@ibhubs.co", role: "Vice President - Talent Accelerator", company: "iB Hubs" },
    { name: "Akhila Chandan", email: "akhila@estuate.com", role: "Associate Vice President Human Resources", company: "Estuate" },
    { name: "Akram Mohammad", email: "akram.mohammad@colruytgroup.com", role: "Deputy Head Head HR", company: "Colruyt India" },
    { name: "Akriti", email: "akriti@elsner.in", role: "HR-Head", company: "Elsner Technologies" },
    { name: "Akshata Bhandare", email: "akshata.bhandare@windmill.ch", role: "HR & Location Head, India", company: "Windmill" },
    { name: "Albino Mascarenhas", email: "albino@pixis.ai", role: "Head - Human Resources Global", company: "Pyxis One" },
    { name: "Allwyn Richard", email: "allwyn.r@qbrainx.com", role: "Head of Human Resources", company: "QBrainX Inc" },
    { name: "Alok Baghel", email: "alok.singh@recro.io", role: "Head Of Talent Management", company: "Recro" },
    { name: "Alok Kumar", email: "alok.kumar@vfislk.com", role: "Operations Leader and Head Transitions, L&D", company: "VFI SLK" },
    { name: "Alwyn Barretto", email: "alwyn.barretto@infrasofttech.com", role: "Head Recruitments", company: "Infrasoft Technologies" },
    { name: "Aman Khan", email: "aman.khan@areteanstech.com", role: "Vice President Human Resources", company: "Areteans" },
    { name: "Amandeep Kaur", email: "amandeep.k@antiersolutions.com", role: "Sr. HR Executive (Technical Recruitment Head)", company: "Antier Solutions" },
    { name: "Amar Sinha", email: "amar.sinha@nitorinfotech.com", role: "Director Talent Acquisition (People Function)", company: "Nitor Infotech" },
    { name: "Ambrish Kanungo", email: "ambrish.kanungo@beyondkey.com", role: "Head of HR", company: "Beyond Key" },
    { name: "Amiit Avaasthi", email: "amiit.avaasthi@altudo.co", role: "Chief People Officer", company: "Altudo" },
    { name: "Amit", email: "amit.malhotra@wundermanthompson.com", role: "Chief People Officer", company: "Wunderman Thompson MSC" },
    { name: "Amit Kataria", email: "amit@hanu.com", role: "Chief Human Resources Officer", company: "Hanu Software" },
    { name: "Amit Prayagi", email: "amit.prayagi@claimgenius.com", role: "Head Of Recruitment & HR Operation", company: "Claim Genius" },
    { name: "Amit Ranjan", email: "amit.ranjan@scikey.ai", role: "Associate Director- Talent Solutions", company: "SCIKEY" },
    { name: "Amit Sahoo", email: "amit.sahoo@areteanstech.com", role: "Vice President and Global Head - Human Resources", company: "Areteans" },
    { name: "Amita Shital", email: "ashital@svam.com", role: "Head of HR", company: "SVAM International" },
    { name: "Amitesh Verma", email: "amitesh.verma@cheersin.com", role: "Associate Director, Talent Acquisition", company: "Cheers Interactive" },
    { name: "Amitha K", email: "amitha.k@secure-24.com", role: "Director- HR", company: "Secure-24" },
  { name: "Amlan Nag", email: "amlan.nag@mjunction.in", role: "General Manager & Head HR", company: "mjunction services" },
  { name: "Amresh Mehra", email: "amreshm@zendrive.com", role: "VP - People & Culture", company: "Zendrive" },
  { name: "Amrita", email: "akishore@dimagi.com", role: "Director of People Operations, India", company: "Dimagi" },
  { name: "Amrita Cheema", email: "amrita.cheema@loconav.com", role: "Head HR - Global SaaS", company: "LocoNav" },
  { name: "Amrita Singh", email: "amrita.singh@cogentinfo.com", role: "Director - Recruitment & Delivery (US Staffing)", company: "COGENT Infotech" },
  { name: "Amrita Singh", email: "amrita.singh@itbd.net", role: "Head HR (India)", company: "IT BY DESIGN" },
  { name: "Amrita Tripathi", email: "amrita@sdnaglobal.com", role: "VP - India, ME and APAC HR", company: "Stanley David and Associates" },
  { name: "Amritesh Shukla", email: "amritesh.shukla@mygate.com", role: "Head Of Human Resources", company: "MyGate" },
  { name: "Amruta Urkude", email: "amruta@greatplaceitservices.com", role: "HR Head (Generalist)", company: "Great Place IT Services" },
  { name: "Amulya", email: "amulya.ms@utthunga.com", role: "Director HR", company: "Utthunga" },
  { name: "Anand Christopher", email: "anand.christopher@grassrootsbpo.com", role: "Vice President Human Resources", company: "Grassroots" },
  { name: "Anand E", email: "anand.e@increff.com", role: "Chief Human Resources Officer", company: "Increff" },
  { name: "Anand K", email: "ak@8kmiles.com", role: "Vice President Human Resources", company: "SecureKloud Technologies" },
  { name: "Anand Khot", email: "anandk@pharmarack.com", role: "Chief Human Resources Officer", company: "Pharmarack" },
  { name: "Anand Rajendran", email: "anand.r@whatarage.com", role: "Director - HR", company: "ADK Rage" },
  { name: "Anand Sasidharan", email: "anand@hubilo.com", role: "Head of Talent Acquisition", company: "Hubilo" },
  { name: "Anand Sl", email: "anand@auzmor.com", role: "HR Director / Operations Head (India)", company: "Auzmor" },
  { name: "Anand Soni", email: "anand@capsitech.com", role: "Talent Acquisition Head", company: "Capsitech" },

    // More HR contacts here...
  ];

  const handlePayment = () => {
    // Redirect to the provided URL
    window.location.href = 'https://topmate.io/hirespoof/1265037';
  };

  const unlockList = () => {
    setIsPaid(true);
  };

  return (
    <div className="email-list-container next-style">
      <Navbar />
      <h1 className="title next-title">Exclusive HR Email List</h1>
      <p className="description next-description">
        Unlock the full list of HR contacts to supercharge your job search! Get access to exclusive contact information.
      </p>
      <ul className="email-list">
        {hrList.slice(0, 10).map((hr, index) => (
          <li key={index} className="email-item next-email-item">
            <span className="next-name">{hr.name}</span> ({hr.company}): <span className="next-email">{hr.email}</span>
          </li>
        ))}
        {isPaid
          ? hrList.slice(10).map((hr, index) => (
              <li key={index + 10} className="email-item next-email-item">
                <span className="next-name">{hr.name}</span> ({hr.company}): <span className="next-email">{hr.email}</span>
              </li>
            ))
          : hrList.slice(10).map((hr, index) => (
              <li key={index + 10} className="email-item next-email-item blurred">
                <span className="next-name">{hr.name}</span> ({hr.company}): <span className="blurred-text">**********</span>
              </li>
            ))}
      </ul>
      {!isPaid && hrList.length > 10 && (
        <div className="blurred-section next-blurred-section">
          <button className="pay-button next-pay-button" onClick={handlePayment}>
            Pay to Unlock Full List
          </button>
          
          <div>
            <center><AdSenseComponent /> </center>
              </div>
        </div>
        
      )}
    </div>
  );
};

export default HrEmailList;



