import React from 'react';
import cor from './assets/icons/cor.png';
import mer from './assets/icons/mer.png';
import './Trending.css';

const Trending = () => {
  return (
    <div className="trending-container">
      <div className="trending-item-container">
        <h2>Google Data Analytics Professional Certificate</h2>
        <a href="https://linksredirect.com/?cid=214566&source=linkkit&url=https%3A%2F%2Fwww.coursera.org%2Fprofessional-certificates%2Fgoogle-data-analytics" target="_blank" rel="noopener noreferrer">
          <img src={cor} alt="Google Data Analytics Professional Certificate" />
        </a>
        <p>Learn from top mentors...</p>
        <a href="https://linksredirect.com/?cid=214566&source=linkkit&url=https%3A%2F%2Fwww.coursera.org%2Fprofessional-certificates%2Fgoogle-data-analytics" target="_blank" rel="noopener noreferrer">
          <button>View Details</button>
        </a>
      </div>
      <div className="trending-item-container">
        <h2>Learning MEAN Stack by Building Real world Application</h2>
        <a href="https://linksredirect.com/?cid=214567&source=linkkit&url=https%3A%2F%2Fwww.coursera.org%2Fspecializations%2Fmean-stack" target="_blank" rel="noopener noreferrer">
          <img src={mer} alt="Learning MEAN Stack by Building Real world Application Specialization" />
        </a>
        <p>Learn from top mentors...</p>
        <a href="https://linksredirect.com/?cid=214567&source=linkkit&url=https%3A%2F%2Fwww.coursera.org%2Fspecializations%2Fmean-stack" target="_blank" rel="noopener noreferrer">
          <button>View Details</button>
        </a>
      </div>
    </div>
  );
};

export default Trending;
