import React, { useEffect } from 'react';

const AdSenseComponent = () => {
  useEffect(() => {
    if (window.adsbygoogle && process.env.NODE_ENV !== 'development') {
      window.adsbygoogle.push({});
    }
  }, []);

  return (
    <div>
      {/* AdSense Ad Unit 1 */}
     <center></center> <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-5241395065023122" crossorigin="anonymous"></script>
<ins
    className="adsbygoogle"
    style={{ display: 'block', textAlign: 'center' }}
    data-ad-client="ca-pub-5241395065023122"
    data-ad-slot="9430237304"
    data-ad-format="fluid"
    data-full-width-responsive="true"
></ins>
<script>
    (adsbygoogle = window.adsbygoogle || []).push({});
</script>

      {/* AdSense Ad Unit 2 */}
      <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-5241395065023122" crossorigin="anonymous"></script>
<ins
    className="adsbygoogle"
    style={{ display: 'block', textAlign: 'center' }}
    data-ad-layout="in-article"
    data-ad-format="fluid"
    data-ad-client="ca-pub-5241395065023122"
    data-ad-slot="2568373224"
></ins>
<script>
    (adsbygoogle = window.adsbygoogle || []).push({});
</script>

    </div>
  );
};

export default AdSenseComponent;
